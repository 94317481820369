// Override default variables before the import

$font-family-sans-serif: 'Source Sans Pro', sans-serif;
$tooltip-max-width: 379px;
$table-cell-padding: 1rem;

$primary: #037fac;
$body-color: black;
$modal-backdrop-opacity: 0.77 !important;
$light: #f9f9f9;
$lightButNotTooLight: #eeeeee;
$e4: #e4e4e4;
$ddd: #dddddd;
$color333: #333;
$danger: #de3535;
$redonblack: #ff5050;
$secondary: #666;
$success: #018503;
$black37: #a1a1a1;
$candidate-bg: #f4f4f4;
$progress-bg: $ddd;

$dropdown-bg: #272727;
$dropdown-link-color: #ffffff;
$dropdown-link-hover-bg: #000000;
$dropdown-link-hover-color: #ffffff;
$dropdown-divider-bg: #373737;
$dropdown-padding-y: 0;
$modal-sm: 500px !default;
$modal-md: 680px !default;
$modal-lg: 975px !default;

$primary37: transparentize($primary, 0.37);
$theme-colors: (
  'primary37': $primary37,
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'light': $light,
  'lightButNotTooLight': $lightButNotTooLight,
  'black37': $black37,
  'ddd': $ddd,
  'e4': $e4,
  'color333': $color333,
  'transparent': transparent,
);
$table-accent-bg: $light;
$input-focus-border-color: transparent;
$input-padding-y: 1rem;
$input-padding-x: 1rem;
$input-focus-box-shadow: 0 0 0 2px rgba(3, 127, 172, 0.37);
$grid-columns: 24 !default;
$grid-gutter-width: 16px !default;
$border-radius: 8px !default;
$border-radius-large: 12px !default;
$border-radius-small: 4px !default;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    25: $spacer * 0.75,
    3: $spacer,
    35: $spacer * 1.25,
    4: $spacer * 1.5,
    42: $spacer * 2,
    45: $spacer * 2.5,
    5: $spacer * 3,
    55: $spacer * 3.5,
    6: $spacer * 4,
    65: $spacer * 8,
    7: $spacer * 11,
  ),
  $spacers
);

$negative-spacers: negativify-map($spacers) !default;

@import '~bootstrap/scss/bootstrap.scss';

.navbar-logo-container {
  display: flex;
  &--sub-page {
    img {
      margin: 0 auto;
    }
  }
  .site-heading {
    border-left: 1px solid rgba(#000, 0.37);
    height: 24px;
    margin-left: 24px;
    padding-left: 24px;
    align-self: center;
    color: #000;
  }
}
.no-text-hover {
  &:hover {
    text-decoration: none !important;
  }
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}